import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import moment from 'moment'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import events from './sampleevents'
import * as Calendarsaga from './Saga/Calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Css/Calendar.css';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import Editor from '../ContractFollow/Editor';
import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';
import ContactEditComponent from '../CustomerContacts/ContactEditComponent';

import FiberNewIcon from '@mui/icons-material/FiberNew';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactsIcon from '@mui/icons-material/Contacts';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import TaskEditor from './TaskEditor';
import EigyoDialog from './EigyoDialog';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';

const DragAndDropCalendar = withDragAndDrop(Calendar)
const globallocalizer = localizer(globalize);


var top = null
var left = null

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
    top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),
  }
});

var useStylesTooltip = makeStyles({
  tooltip: {
    fontSize: "12px",
    color:"black",
    backgroundColor: "white",
    border:"1px solid #6c757d",
    padding:"1px 3px",
    marginTop:"-5px"
  },
});

function TaskDialog(props) {


  const classes = useStyles();
  const { onClose, selectedValue, open, event_type, section_id, change_contract_follow_edit_modal, contract_follow_edit_modal, change_customer_contacts_modal, customer_contacts_modal } = props;


  const handleClose = () => {
    alert()
    onClose(event_type);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const close_contract_follow_edit_modal = (value) => {
    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  const close_customer_contacts_modal = (value) => {

    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  const close_eigyo_edit_modal = (value) => {

    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };


  return (
    <Dialog
      classes={{
        paper: classes.dialog
      }}
      maxWidth={event_type === 6 ? "xl":"sm"}
      key={section_id}
      id={section_id}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={event_type === 1 ? customer_contacts_modal : (event_type === 2 ? contract_follow_edit_modal : open)}>



      {event_type === 1 ? <ContactEditComponent onClose={close_customer_contacts_modal} />
        : event_type === 2 ? <Editor onClose={close_contract_follow_edit_modal} /> :
        event_type === 6 ? <EigyoDialog onClose={close_eigyo_edit_modal} /> :
          <TaskEditor onClose={handleClose} />}


      {/* {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <button className="btn btn-primary float-right" type="button" onClick={this.props.add_rent_customer_contacts}>保存</button>}
      {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.state.contactstate && this.state.contactstate.id ? <button className="btn btn-danger float-right" type="button" onClick={this.toggledelete}>削除</button> : "")}*/}
    </Dialog>
  );
}

function eventStyleGetter(event, start, end, isSelected) {
  // console.log(event);
  //type:1, // 0:出勤 1:? 2:来店とか 3:タスク 4:予定件数 intend1 予定 
  // var backgroundColor = event.type === 0 ? '#fffacd' : event.type === 4 ? '#e0e0e0' : event.type == 3 ? event.color : event.type == 2 && event.progress != 2 ? "#F7A46B" : event.type == 2 && event.progress == 2 ? "#a9a9a9" : event.intend ? '#98fb98' : '#87cefa';
  // var backgroundColor = event.id === 0 ? '#fffacd' : event.intend ? '#98fb98' : '#87cefa';
  var backgroundColor = ""
  if (event.type === 0) {// 出勤
    backgroundColor = '#fffacd' // クリーム
  } else if (event.type === 4) {// 予定
    backgroundColor = '#e0e0e0' // グレイ
  } else if (event.type == 3) {// タスク
    // if(event.completed_at){
    //   backgroundColor = '#a9a9a9'
    // }else{
      backgroundColor = event.color
    // }
  } else if (event.type == 2) {// 来店
    // if (event.progress != 2) {
      // backgroundColor = "#F7A46B"// オレンジ
      backgroundColor = "#F9A7A7"// 赤
    // } else {
    //   backgroundColor = "#a9a9a9"// グレイ
    // }
  } else if (event.type == 1) {// 来店
    // if (event.intend) {// 予定
      // event.returning == 0 && event.rent_hearing_sheet_id > 0 ? backgroundColor = '#95C195' : backgroundColor = '#98fb98' // 黄緑
      backgroundColor = "#98fb98"// 黄緑
    // } else {
    //   // backgroundColor = '#87cefa' // 水色
    //   backgroundColor = '#a9a9a9' // グレイ
    // }
  } else if(event.type == 6){// オーナー
    // if(event.no_count == 1){
      // backgroundColor = '#cec9f7' // 薄紫
      backgroundColor = '#F9A7A7' // 薄い赤
    // }else{
    //   backgroundColor = "#a9a9a9"// グレイ
    // }
    
  }
  
  var style = {
    backgroundColor: backgroundColor,
    borderRadius: '2px',
    borderColor: '#FFF',
    // opacity: 0.8,
    color: '#4d4d4d',
    border: '0px',
    display: 'block',
  };
  return {
    style: style
  };
}



class RBCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: events,
      baseDate: this.props.calendar_all_store_list_form.start,
      cardx: window.innerWidth - 600,
      open_event_editor: false,
      event_type: 3,
      precalendar: [],

    }


    let calendar_all_store_list_form = this.props.calendar_all_store_list_form

    // calendar_all_store_list_form.section_id = this.props.user_details.amSectionId
    this.props.change_calendar_all_store_list_form(calendar_all_store_list_form)

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)
    this.editEvent = this.editEvent.bind(this)

    this.viewEvent = this.viewEvent.bind(this)


    this.resizeEvent = this.resizeEvent.bind(this)
    this.Event = this.Event.bind(this)
    this.onNavigate = this.onNavigate.bind(this)

  }
  componentDidMount() {
    this.onNavigate(new Date(this.props.calendar_all_store_list_form.start ? this.props.calendar_all_store_list_form.start : new Date()), "day");
  }


  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {

    if(event.type == 3 && event.id == 0){//来店枠は新規
      this.props.change_calendar_precalendar(this.props.calendar_list)
  
      console.log('newEvent')

      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.id = ""
      rent_task_detail.title = ""
      rent_task_detail.is_free = 0
      rent_task_detail.section_id = event.section_id
      rent_task_detail.user_id = event.user_id
      rent_task_detail.together_user_id = []
      rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
      rent_task_detail.remarks = ""
      rent_task_detail.color = "#BDEBF9"
      rent_task_detail.event_type = 0
      rent_task_detail.completed_at = null

      rent_task_detail.start_at_pre = moment(event.start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at_pre = moment(event.end).format('YYYY/MM/DD HH:mm')

      rent_task_detail.diff = moment(end).diff(moment(start), 'minutes')

      this.props.change_rent_task_detail(rent_task_detail)

      this.setState({ event_type: 3 })

      this.props.change_task_edit_modal(true)

      this.props.change_calendar_event_type(3)

      this.props.set(3)
      return
    }



    // this.setState({ precalendar: this.props.calendar_list })
    this.props.change_calendar_precalendar(this.props.calendar_list)
    // alert(start)
    // console.log(event)
    // alert(moment(event.date).startOf('day'))
    start = new Date(moment(moment(start).startOf('day')) > moment(start) ? moment(start).startOf('day') : start)
    end = new Date(moment(moment(start).endOf('day')) < moment(end) ? moment(start).endOf('day') : end)

    // alert(end)

    useStyles = makeStyles({
      avatar: {
        // backgroundColor: blue[100],
        // color: blue[600],
      },
      dialog: {
        position: 'absolute',
        left: null,
        top: null,
        width: 600,
        height: 580
      }
    });

    if (event.type == 3) {


      const events = this.props.calendar_list
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)


      this.props.change_calendar_list(nextEvents)
      // alert(event.type.section_id)
      // console.log(event)
      let rent_task_detail = this.props.rent_task_detail

      rent_task_detail.id = event.id
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
      rent_task_detail.title = event.title
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at

      rent_task_detail.start_at_pre = moment(event.start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at_pre = moment(event.end).format('YYYY/MM/DD HH:mm')

      rent_task_detail.diff = moment(end).diff(moment(start), 'minutes')


      this.props.change_rent_task_detail(rent_task_detail)



    } else if (event.type === 1) {
      // alert(start)

      const events = this.props.calendar_list
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)


      this.props.change_calendar_list(nextEvents)

      let start_edit = start
      // 時間外から引っ張り出してきたらスタート時間が30分ずれるのを回避
      if (moment(event.start).tz("Asia/Tokyo").hour() < 9 || moment(event.start).tz("Asia/Tokyo").hour() > 18) {
        // alert(moment(start).tz("Asia/Tokyo").hour())
        start_edit = moment(start).add(-30, 'minutes')

      }

      let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

      rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
      rent_customer_contacts_forms.section_id = event.section_id;
      rent_customer_contacts_forms.user_id = event.user_id;
      rent_customer_contacts_forms.date = moment(start).format("YYYY/MM/DD");
      rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
      rent_customer_contacts_forms.way = event.way ? event.way : '';
      rent_customer_contacts_forms.going = event.going ? event.going : 0;
      rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
      rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
      rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
      rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
      rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
      rent_customer_contacts_forms.online = event.online ? event.online : 0;
      rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
      rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
      rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
      rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
      rent_customer_contacts_forms.agent_name = event.agent_name;
      rent_customer_contacts_forms.agent_etc = event.agent_etc;

      rent_customer_contacts_forms.start = moment(start_edit).format('YYYY/MM/DD HH:mm')
      rent_customer_contacts_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

      rent_customer_contacts_forms.id = event.id ? event.id : 0;
      rent_customer_contacts_forms.title = event.title ? event.title : "";

      this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)

    } else if (event.type === 2) {

      const events = this.props.calendar_list
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)


      this.props.change_calendar_list(nextEvents)
      // alert(start)
      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      // contract_follow_editer.date = moment(start).utc().format("YYYY/MM/DD");
      contract_follow_editer.date = moment(start).format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;
      // contract_follow_editer.name = "dddd";
      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;
      // contract_follow_editer.name2 = event.name2;
      // alert(moment(start).format('YYYY/MM/DD HH:mm'))
      contract_follow_editer.datetime_start = new Date(moment(start).format('YYYY/MM/DD HH:mm'))
      contract_follow_editer.datetime_end = new Date(moment(end).format('YYYY/MM/DD HH:mm'))

      contract_follow_editer.date_pre = moment(event.date).format('YYYY/MM/DD')
      contract_follow_editer.start_at_pre = moment(event.datetime_start).format('YYYY/MM/DD HH:mm')
      contract_follow_editer.end_at_pre = moment(event.datetime_end).format('YYYY/MM/DD HH:mm')
      // user_id: 'crm.rent_contract_follow_details.

      this.props.change_contract_follow_editer(contract_follow_editer)
    } else if (event.type === 6) {

      const events = this.props.calendar_list
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)


      this.props.change_calendar_list(nextEvents)
      // alert(start)



      let eigyou_detail = this.props.eigyou_detail

      eigyou_detail.section_id = event.section_id;
      eigyou_detail.user_id = event.user_id;

      eigyou_detail.date = moment(event.date).format("YYYY/MM/DD");

      eigyou_detail.type = event.type6 ? event.type6 : 1 //1:オーナー　2:法人
      eigyou_detail.before_owner_id = event.owner_id ? event.owner_id :""
      eigyou_detail.owner_id = event.owner_id ? event.owner_id :""
      eigyou_detail.eigyou_no = event.eigyou_no ? event.eigyou_no : ""
      eigyou_detail.yoyaku_date = event.date ? moment(event.date).format("YYYY/MM/DD") : ""
      eigyou_detail.start_at = start ? moment(start).format('HH:mm')  : ""
      eigyou_detail.start_at = eigyou_detail.start_at == "00:00" ? "" : eigyou_detail.start_at 
      eigyou_detail.end_at = end ? moment(end).format('HH:mm') : ""
      eigyou_detail.end_at = eigyou_detail.end_at == "00:00" ? "" : eigyou_detail.end_at 
      eigyou_detail.no_count = event.no_count ? event.no_count : 0
      eigyou_detail.tantou_user_id = event.is_together ? event.back_user_id : event.user_id
      eigyou_detail.raiten_kbn_no = event.raiten_kbn_no ? event.raiten_kbn_no : ""
      eigyou_detail.outai_naiyou_no = event.outai_naiyou_no ? event.outai_naiyou_no : ""
      eigyou_detail.outai_naiyou_id = event.outai_naiyou_id ? event.outai_naiyou_id : ""
      eigyou_detail.staff = event.staff ? event.staff : ""
      eigyou_detail.suggestions = event.suggestions ? event.suggestions : ""
      eigyou_detail.outai_shubetsu_no = event.outai_shubetsu_no ? event.outai_shubetsu_no : ""
      eigyou_detail.naiyou = event.naiyou ? event.naiyou : ""
      eigyou_detail.komatte = event.komatte ? event.komatte : ""
      eigyou_detail.promise = event.promise ? event.promise : ""
      eigyou_detail.confirmers = event.confirmers ? event.confirmers : ""
      eigyou_detail.responder = event.responder ? event.responder : ""

      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
        },this)
      }
      eigyou_detail.together_user_id = together_user_ids

      let outai_naiyou_ids = []
      if(event.outai_naiyou_id && event.outai_naiyou_id != 0){
        if(event.type6 == 1){
          event.outai_naiyou_id.split(',').map(function (item) {
            outai_naiyou_ids.push(this.props.tm_owner_outai_naiyou_list.filter((a)=>a.value == item)[0])
          },this)
        }
      }
      eigyou_detail.outai_naiyou_id = outai_naiyou_ids

      this.props.change_eigyou_detail(eigyou_detail)
      
    }


    if (event.type === 1) {

      this.props.change_customer_contacts_modal(true)
    } else if (event.type === 2) {
      this.props.change_contract_follow_edit_modal(true)
    } else if (event.type === 3) {
      this.props.change_task_edit_modal(true)
    } else if (event.type === 6) {
      this.props.change_eigyo_edit_modal(true)
      if(event.type6 == 1 && event.owner_id > 0){
        this.props.get_eigyou_owner_room(event.owner_id)
        this.props.get_t_owner_plan_counts_list({owner_id: event.owner_id, eigyou_no: event.id})     
      }else if(event.type6 == 2 && event.owner_id > 0){
        this.props.get_eigyou_rent_m_corporate_all_list(event.owner_id)
      }
    }

    this.props.change_calendar_event_type(event.type)

    this.setState({ event_type: event.type })
    this.props.set(event.type)




    // }

    // this.setState({
    //   events: nextEvents,
    // })
    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }


  resizeEvent = ({ event, start, end }) => {


    if(event.type == 3 && event.id == 0){//来店枠は新規
      this.props.change_calendar_precalendar(this.props.calendar_list)
  
      console.log('newEvent')

      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.id = ""
      rent_task_detail.title = ""
      rent_task_detail.is_free = 0
      rent_task_detail.section_id = event.section_id
      rent_task_detail.user_id = event.user_id
      rent_task_detail.together_user_id = []
      rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
      rent_task_detail.remarks = ""
      rent_task_detail.color = "#BDEBF9"
      rent_task_detail.event_type = 0
      rent_task_detail.completed_at = null

      rent_task_detail.start_at_pre = moment(event.start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at_pre = moment(event.end).format('YYYY/MM/DD HH:mm')

      rent_task_detail.diff = moment(end).diff(moment(start), 'minutes')

      this.props.change_rent_task_detail(rent_task_detail)

      this.setState({ event_type: 3 })

      this.props.change_task_edit_modal(true)

      this.props.change_calendar_event_type(3)

      this.props.set(3)
      return
    }

    
    this.props.change_calendar_precalendar(this.props.calendar_list)


    start = new Date(moment(moment(start).startOf('day')) > moment(start) ? moment(start).startOf('day') : start)
    end = new Date(moment(moment(start).endOf('day')) < moment(end) ? moment(start).endOf('day') : end)
    // alert(event.type)
    // alert(start)
    if (event.type == 3) {

      this.setState({ event_type: 3 })
      const nextEvents = this.props.calendar_list.map(existingEvent => {
        var ret = existingEvent;
        if (existingEvent.id == event.id) {
          //日付けを変える変更はさせない
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };
        } else {
          ret = existingEvent;
        }
        return ret;
      })
      this.props.change_calendar_list(nextEvents)
      // alert(event.type.section_id)
      // console.log(event)
      let rent_task_detail = this.props.rent_task_detail

      rent_task_detail.id = event.id
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.is_free = 0
      rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')

      rent_task_detail.title = event.title
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at

      rent_task_detail.start_at_pre = moment(event.start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at_pre = moment(event.end).format('YYYY/MM/DD HH:mm')

      rent_task_detail.diff = moment(end).diff(moment(start), 'minutes')

      this.props.change_rent_task_detail(rent_task_detail)


    } else if (event.type === 1) {

      this.setState({ event_type: 1 })



      const nextEvents = this.props.calendar_list.map(existingEvent => {
        var ret = existingEvent;
        if (existingEvent.id == event.id) {
          //日付けを変える変更はさせない
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };
        } else {
          ret = existingEvent;
        }
        return ret;
      })


      this.props.change_calendar_list(nextEvents)



      let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms


      rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
      rent_customer_contacts_forms.section_id = event.section_id;
      rent_customer_contacts_forms.user_id = event.user_id;

      rent_customer_contacts_forms.date = moment(event.date).format("YYYY/MM/DD");
      rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
      rent_customer_contacts_forms.way = event.way ? event.way : '';
      rent_customer_contacts_forms.going = event.going ? event.going : 0;
      rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
      rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
      rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
      rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
      rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
      rent_customer_contacts_forms.online = event.online ? event.online : 0;
      rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
      rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
      rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
      rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
      rent_customer_contacts_forms.agent_name = event.agent_name;
      rent_customer_contacts_forms.agent_etc = event.agent_etc;

      rent_customer_contacts_forms.start = moment(start).format('YYYY/MM/DD HH:mm')
      rent_customer_contacts_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

      rent_customer_contacts_forms.id = event.id ? event.id : 0;

      rent_customer_contacts_forms.title = event.title ? event.title : "";
      this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)

    } else if (event.type === 2) {
      this.setState({ event_type: 2 })
      const nextEvents = this.props.calendar_list.map(existingEvent => {
        var ret = existingEvent;

        if (existingEvent.rent_contract_id == event.rent_contract_id && existingEvent.rent_m_contract_follow_id == event.rent_m_contract_follow_id) {
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };


        } else {
          ret = existingEvent;
        }
        return ret;
      })

      this.props.change_calendar_list(nextEvents)
      // alert(start)
      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      contract_follow_editer.date = moment(event.date).utc().format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;
      // contract_follow_editer.name = "dddd";
      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;
      // contract_follow_editer.name2 = event.name2;


      contract_follow_editer.datetime_start = new Date(moment(start).format('YYYY/MM/DD HH:mm'))
      contract_follow_editer.datetime_end = new Date(moment(end).format('YYYY/MM/DD HH:mm'))


      this.props.change_contract_follow_editer(contract_follow_editer)

    } else if (event.type === 6) {

      this.setState({ event_type: 6 })
      this.props.change_calendar_event_type(6)
      const nextEvents = this.props.calendar_list.map(existingEvent => {
        var ret = existingEvent;

        if (existingEvent.id == event.id && existingEvent.owner_id == event.owner_id) {
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };


        } else {
          ret = existingEvent;
        }
        return ret;
      })

      this.props.change_calendar_list(nextEvents)


      console.log(event)

      let eigyou_detail = this.props.eigyou_detail

      eigyou_detail.section_id = event.section_id;
      eigyou_detail.user_id = event.user_id;

      eigyou_detail.date = moment(event.date).format("YYYY/MM/DD");

      eigyou_detail.type = event.type6 ? event.type6 : 1 //1:オーナー　2:法人
      eigyou_detail.before_owner_id = event.owner_id ? event.owner_id :""
      eigyou_detail.owner_id = event.owner_id ? event.owner_id :""
      eigyou_detail.eigyou_no = event.eigyou_no ? event.eigyou_no : ""
      eigyou_detail.yoyaku_date = event.date ? moment(event.date).format("YYYY/MM/DD") : ""
      eigyou_detail.start_at = start ? moment(start).format('HH:mm')  : ""
      eigyou_detail.start_at = eigyou_detail.start_at == "00:00" ? "" : eigyou_detail.start_at 
      eigyou_detail.end_at = end ? moment(end).format('HH:mm') : ""
      eigyou_detail.end_at = eigyou_detail.end_at == "00:00" ? "" : eigyou_detail.end_at 
      eigyou_detail.no_count = event.no_count ? event.no_count : 0
      eigyou_detail.tantou_user_id = event.is_together ? event.back_user_id : event.user_id
      eigyou_detail.raiten_kbn_no = event.raiten_kbn_no ? event.raiten_kbn_no : ""
      eigyou_detail.outai_naiyou_no = event.outai_naiyou_no ? event.outai_naiyou_no : ""
      eigyou_detail.outai_naiyou_id = event.outai_naiyou_id ? event.outai_naiyou_id : ""
      eigyou_detail.staff = event.staff ? event.staff : ""
      eigyou_detail.suggestions = event.suggestions ? event.suggestions : ""
      eigyou_detail.outai_shubetsu_no = event.outai_shubetsu_no ? event.outai_shubetsu_no : ""
      eigyou_detail.naiyou = event.naiyou ? event.naiyou : ""
      eigyou_detail.komatte = event.komatte ? event.komatte : ""
      eigyou_detail.promise = event.promise ? event.promise : ""
      eigyou_detail.confirmers = event.confirmers ? event.confirmers : ""
      eigyou_detail.responder = event.responder ? event.responder : ""

      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
        },this)
      }
      eigyou_detail.together_user_id = together_user_ids

      let outai_naiyou_ids = []
      if(event.outai_naiyou_id && event.outai_naiyou_id != 0){
        if(event.type6 == 1){
          event.outai_naiyou_id.split(',').map(function (item) {
            outai_naiyou_ids.push(this.props.tm_owner_outai_naiyou_list.filter((a)=>a.value == item)[0])
          },this)
        }
      }
      eigyou_detail.outai_naiyou_id = outai_naiyou_ids

      this.props.change_eigyou_detail(eigyou_detail)

      
    }

    if (event.type === 1) {
      this.props.change_customer_contacts_modal(true)
    } else if (event.type === 2) {
      this.props.change_contract_follow_edit_modal(true)
    } else if (event.type === 3) {
      this.props.change_task_edit_modal(true)
    } else if (event.type === 6) {  
      if(event.type6 == 1 && event.owner_id > 0){
        this.props.get_eigyou_owner_room(event.owner_id)
        this.props.get_t_owner_plan_counts_list({owner_id: event.owner_id, eigyou_no: event.id})     
      }else if(event.type6 == 2 && event.owner_id > 0){
        this.props.get_eigyou_rent_m_corporate_all_list(event.owner_id)
      }
      this.props.change_eigyo_edit_modal(true)
    }

    this.props.change_calendar_event_type(event.type)

    this.setState({ event_type: event.type })
    this.props.set(event.type)

  }

  newEvent = ({ start, end }) => {
    this.props.change_calendar_precalendar(this.props.calendar_list)
 
    console.log('newEvent')

    let rent_task_detail = this.props.rent_task_detail
    rent_task_detail.id = ""
    rent_task_detail.title = ""
    rent_task_detail.is_free = 0
    rent_task_detail.section_id = this.props.section_id
    rent_task_detail.user_id = this.props.user_details.id
    rent_task_detail.together_user_id = []
    rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
    // rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
    rent_task_detail.end_at = moment(moment(start).format("YYYY/MM/DD ") + moment(end).format("HH:mm")).format('YYYY/MM/DD HH:mm')
    rent_task_detail.remarks = ""
    rent_task_detail.color = "#BDEBF9"
    rent_task_detail.event_type = 0
    rent_task_detail.completed_at = null

    rent_task_detail.diff = moment(end).diff(moment(start), 'minutes')

    this.props.change_rent_task_detail(rent_task_detail)

    this.setState({ event_type: 3 })

    this.props.change_task_edit_modal(true)

    this.props.change_calendar_event_type(3)

    this.props.set(3)

  }

  editEvent = (event) => {

    if(event.type == 3 && event.id == 0){//来店枠は新規
      this.props.change_calendar_precalendar(this.props.calendar_list)
  
      console.log('newEvent')

      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.id = ""
      rent_task_detail.title = ""
      rent_task_detail.is_free = 0
      rent_task_detail.section_id = event.section_id
      rent_task_detail.user_id = event.user_id
      rent_task_detail.together_user_id = []
      rent_task_detail.start_at = moment(event.start_edit).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(event.end_edit).format('YYYY/MM/DD HH:mm')
      rent_task_detail.remarks = ""
      rent_task_detail.color = "#BDEBF9"
      rent_task_detail.event_type = 0
      rent_task_detail.completed_at = null

      rent_task_detail.diff = moment(event.end_edit).diff(moment(event.start_edit), 'minutes')

      this.props.change_rent_task_detail(rent_task_detail)

      this.setState({ event_type: 3 })

      this.props.change_task_edit_modal(true)

      this.props.change_calendar_event_type(3)

      this.props.set(3)
      return
    }




    this.props.change_calendar_precalendar(this.props.calendar_list)

    if (event.type === 3) {
      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.id = event.id
      rent_task_detail.is_free = 0
      rent_task_detail.title = event.title
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.start_at = moment(event.start_edit).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(event.end_edit).format('YYYY/MM/DD HH:mm')
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at
      rent_task_detail.diff = moment(event.end_edit).diff(moment(event.start_edit), 'minutes')
      this.props.change_rent_task_detail(rent_task_detail)

    } else if (event.type === 2) {

      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      // contract_follow_editer.date = moment(event.date).utc().format("YYYY/MM/DD");
      contract_follow_editer.date = moment(event.date).utc().format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      // alert(contract_follow_editer.is_time)
      // alert(event.is_time)
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;

      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;
      // contract_follow_editer.name2 = event.name2;
      contract_follow_editer.datetime_start = event.start_edit;
      contract_follow_editer.datetime_end = event.end_edit;

      // user_id: 'crm.rent_contract_follow_details.
      this.props.change_contract_follow_editer(contract_follow_editer)
      // this.props.change_rent_customer_contacts_forms_modal(!this.props.rent_customer_contacts_forms_modal)

    } else if (event.type === 1) {
      // alert(event.type)
      console.log(event)
      // alert(event.date)
      // alert(event.type)
      let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms


      rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
      rent_customer_contacts_forms.section_id = event.section_id;
      rent_customer_contacts_forms.user_id = event.user_id;

      rent_customer_contacts_forms.date = moment(event.date).format("YYYY/MM/DD");
      rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
      rent_customer_contacts_forms.way = event.way ? event.way : '';
      rent_customer_contacts_forms.going = event.going ? event.going : 0;
      rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
      rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
      rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
      rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
      rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
      rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
      rent_customer_contacts_forms.online = event.online ? event.online : 0;
      rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
      rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
      rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
      rent_customer_contacts_forms.agent_name = event.agent_name;
      rent_customer_contacts_forms.agent_etc = event.agent_etc;
      rent_customer_contacts_forms.intend_time = event.intend_time ? event.intend_time : 0;
      rent_customer_contacts_forms.intend_time2 = event.intend_time2 ? event.intend_time2 : 0;

      rent_customer_contacts_forms.start = event.start_edit ? moment(event.start_edit).format("YYYY/MM/DD HH:mm") : null;
      rent_customer_contacts_forms.end = event.end_edit ? moment(event.end_edit).format("YYYY/MM/DD HH:mm") : null;
  
      rent_customer_contacts_forms.id = event.id ? event.id : 0;
      rent_customer_contacts_forms.title = event.title ? event.title : "";
      this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
    } else if (event.type === 6) {
      // alert(event.type)
      console.log(event)
      // alert(event.date)
      // alert(event.type)
      let eigyou_detail = this.props.eigyou_detail

      eigyou_detail.section_id = event.section_id;
      eigyou_detail.user_id = event.user_id;

      eigyou_detail.type = event.type6 ? event.type6 : 1 //1:オーナー　2:法人
      eigyou_detail.before_owner_id = event.owner_id ? event.owner_id :""
      eigyou_detail.owner_id = event.owner_id ? event.owner_id :""
      eigyou_detail.eigyou_no = event.id ? event.id : ""
      eigyou_detail.yoyaku_date = event.date ? moment(event.date).format("YYYY/MM/DD") : ""
      eigyou_detail.start_at = event.start_edit ? moment(event.start_edit).format('HH:mm')  : ""
      eigyou_detail.start_at = eigyou_detail.start_at == "00:00" ? "" : eigyou_detail.start_at 
      eigyou_detail.end_at = event.end_edit ? moment(event.end_edit).format('HH:mm') : ""
      eigyou_detail.end_at = eigyou_detail.end_at == "00:00" ? "" : eigyou_detail.end_at 
      eigyou_detail.no_count = event.no_count ? event.no_count : 0
      eigyou_detail.tantou_user_id = event.is_together ? event.back_user_id : event.user_id
      eigyou_detail.raiten_kbn_no = event.raiten_kbn_no ? event.raiten_kbn_no : ""
      eigyou_detail.outai_naiyou_no = event.outai_naiyou_no ? event.outai_naiyou_no : ""
      eigyou_detail.outai_naiyou_id = event.outai_naiyou_id ? event.outai_naiyou_id : ""
      eigyou_detail.staff = event.staff ? event.staff : ""
      eigyou_detail.suggestions = event.suggestions ? event.suggestions : ""
      eigyou_detail.outai_shubetsu_no = event.outai_shubetsu_no ? event.outai_shubetsu_no : ""
      eigyou_detail.naiyou = event.naiyou ? event.naiyou : ""
      eigyou_detail.komatte = event.komatte ? event.komatte : ""
      eigyou_detail.promise = event.promise ? event.promise : ""
      eigyou_detail.confirmers = event.confirmers ? event.confirmers : ""
      eigyou_detail.responder = event.responder ? event.responder : ""

      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
        },this)
      }
      eigyou_detail.together_user_id = together_user_ids

      let outai_naiyou_ids = []
      if(event.outai_naiyou_id && event.outai_naiyou_id != 0){
        if(event.type6 == 1){
          event.outai_naiyou_id.split(',').map(function (item) {
            outai_naiyou_ids.push(this.props.tm_owner_outai_naiyou_list.filter((a)=>a.value == item)[0])
          },this)
        }
      }
      eigyou_detail.outai_naiyou_id = outai_naiyou_ids

      this.props.change_eigyou_detail(eigyou_detail)
      
      if(event.type6 == 1 && event.owner_id > 0){
        this.props.get_eigyou_owner_room(event.owner_id)
        this.props.get_t_owner_plan_counts_list({owner_id: event.owner_id, eigyou_no: event.id})     
      }else if(event.type6 == 2 && event.owner_id > 0){
        this.props.get_eigyou_rent_m_corporate_all_list(event.owner_id)
      }
      
    }


    if (event.type === 1) {

      this.props.change_customer_contacts_modal(true)
    } else if (event.type === 2) {
      this.props.change_contract_follow_edit_modal(true)
    } else if (event.type === 3) {
      this.props.change_task_edit_modal(true)
    } else if (event.type === 6) {
      this.props.change_eigyo_edit_modal(true)
    }

    this.props.change_calendar_event_type(event.type)

    this.setState({ event_type: event.type })
    this.props.set(event.type)
    // this.doClick()


    // let idList = this.props.calendar_list.map(a => a.id)
    // let newId = 1
    // if (idList.length > 0) {
    //   newId = Math.max(...idList) + 1
    // }
    // console.log('newId')
    // console.log(idList)
    // console.log(newId)

    // let event = {
    //   id: newId,
    //   title: title,
    //   start: start,
    //   end: end,
    // }

  }

  dblclick = (event) => {
    // alert(event.target.getBoundingClientRect().x)
    console.log(event)
    // let cur_id = event.id;
    // var evList = []
    // this.props.calendar_list.map(function (value) {
    //   if (value.id != cur_id) {
    //     evList.push(value)
    //   }
    // });

    // console.log(evList);
    // this.props.change_calendar_list(evList)
    // this.setState({
    //   events: evList
    // })
  }

  onMouseDown(view) {
    console.log('onMouseDown')
    console.log(view)
    // alert(view)
  }

  onTouchStart(view) {
    console.log('onTouchStart')
    console.log(view)
    // alert(view)
  }

  viewEvent(view) {

    this.onNavigate(this.state.baseDate, view)
    console.log(view)
    // alert(view)
  }
  onNavigate = (date, view) => {
    let start, end;

    this.setState({ baseDate: date })

    if (view === 'month') {
      start = moment(date).startOf('month').startOf('week').startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).endOf('month').endOf('week').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    if (view === 'agenda') {
      start = moment(date).startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).add(1, 'months').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    if (view === 'week') {
      start = moment(date).add(-6, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).add(6, 'days').endOf('day').format('YYYY/MM/DD HH:mm:ss')

    }
    if (view === 'day') {
      start = moment(date).startOf('day').format('YYYY/MM/DD HH:mm:ss')

      end = moment(date).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }


    console.log(start, end);

    let calendar_all_store_list_form = this.props.calendar_all_store_list_form
    calendar_all_store_list_form.start = start
    calendar_all_store_list_form.end = end
    // calendar_all_store_list_form.section_id = 1
    this.props.change_calendar_all_store_list_form(calendar_all_store_list_form)
    // this.props.get_calendar_list()
    return console.log({ start, end });
  }
  click(e) {
    // alert(e.target.getBoundingClientRect().top)
    // alert(e.target.getBoundingClientRect().left)
    // this.setState({ event_type: e.type })
    top = e.target.getBoundingClientRect().top
    left = e.target.getBoundingClientRect().left
    // this.handleClickOpen()

    useStyles = makeStyles({
      avatar: {
        // backgroundColor: blue[100],
        // color: blue[600],
      },
      dialog: {
        position: 'absolute',
        left: (this.props.calendar_event_type === 6 ? 960:600) + left <= window.innerWidth ? left : (left - (this.props.calendar_event_type === 6 ? 960:600) > 0 ? left - (this.props.calendar_event_type === 6 ? 960:600) : 0),
        top: 600 + top <= window.innerHeight ? top : (top - 600 > 0 ? top - 580 : 0),
      }
    });

    // console.log(e)
    // alert(e.target.getBoundingClientRect().x)
    // if (e.target.getBoundingClientRect().x - 600 < 0 && e.target.getBoundingClientRect().x + 600 > window.innerWidth) {
    //   this.setState({ cardx: 0 })
    // } else if (e.target.getBoundingClientRect().x - 600 >= 0) {
    //   this.setState({ cardx: e.target.getBoundingClientRect().x - 600 })

    // }
    // else if (e.target.getBoundingClientRect().x + 580 >= 0) {
    //   this.setState({ cardx: e.target.getBoundingClientRect().x })

    // }
    // this.setState({ open_event_editor: !this.state.open_event_editor })
  }

  handleClickOpen = () => {
    // alert(this.state.event_type)

  };

  handleClose = (value) => {

    if (value === 3) {
      // this.setState({ open: false })
      this.props.change_task_edit_modal(false)
      // let rent_task_detail = this.props.rent_task_detail

      // if (rent_task_detail.start_at_pre) {

      //   let calendar_list = this.props.calendar_list
      //   // 一覧のもとにもどす。
      //   calendar_list = calendar_list.map(function (value) {
      //     if (value.type == 3 && value.id === rent_task_detail.id) {
      //       // alert(rent_task_detail.start_at_pre)
      //       value.start = new Date(moment(rent_task_detail.start_at_pre).format('YYYY/MM/DD HH:mm'))
      //       value.end = new Date(moment(rent_task_detail.end_at_pre).format('YYYY/MM/DD HH:mm'))

      //       // value.end = moment(rent_task_detail.end_at_pre).utc().format('YYYY/MM/DD HH:mm')
      //     }
      //     return value
      //   })


      //   this.props.change_calendar_list(calendar_list)
      // }

      // rent_task_detail.id = ""
      // rent_task_detail.title = ""
      // rent_task_detail.section_id = ""
      // rent_task_detail.user_id = ""
      // rent_task_detail.start_at = ""
      // rent_task_detail.end_at = ""
      // rent_task_detail.remarks = ""
      // rent_task_detail.color = "#F9A7A7"
      // rent_task_detail.start_at_pre = ""
      // rent_task_detail.end_at_pre = ""

      this.props.change_calendar_list(this.state.precalendar)


      // // alert( this.props.user_details.id)
      // this.props.change_rent_task_detail(rent_task_detail)

    } else if (value === 2) {

      this.props.change_contract_follow_edit_modal(false)

      this.props.change_calendar_list(this.state.precalendar)



    } else if (value === 1) {

      this.props.change_customer_contacts_modal(false)

      this.props.change_calendar_list(this.state.precalendar)



    } else if (value === 6) {

      this.props.change_eigyo_edit_modal(false)

      // this.props.change_calendar_list(this.state.precalendar)
      this.props.change_calendar_list(this.props.calendar_precalendar)


    }






    this.setState({ reflesh: !this.state.reflesh })


  };
  // holidayの数値によってその日のシフトが決まります↓
  // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
  // NULLの場合も出勤

  // console.log(f)
  Event = ({ event }) => {
    const classes = useStylesTooltip();
    if (event.type === 0) {
      // alert()
      return (
        // 槙塚さん入れるときは下側
        // <Tooltip title={event.users.filter(a => a.section_id == this.props.section_id && (a.holiday === 0 || a.holiday === 3 || a.holiday === 5 || a.holiday === 6 || a.holiday === 7 || a.holiday === 8 || a.holiday === 12 || a.holiday === 13)).map(function (value) {
        //   return <div>{value.user_name_all}{value.holiday === 3 ? "(AM半休)" : value.holiday === 5 ? "(遅刻)" : value.holiday === 6 ? "(早退)" : value.holiday === 7 ? "(出張)" : value.holiday === 12 ? "(PM半休)" : value.holiday === 12 ? "(外出)" : ""} </div>
        <Tooltip title={event.users.filter(a => (
          (a.section_id == this.props.section_id)
          // (this.props.section_id == 2 && a.id == 1124) // 小野
          // (this.props.section_id == 17 && a.id == 1020) || 
          // (this.props.section_id == 4 && a.id == 1109) // 清藤
          || (this.props.section_id == 6 && a.id == 1015) // 吉田 中央公園
          // || (this.props.section_id == 3 && a.id == 1015) // 吉田 駅前
          // (this.props.section_id == 3 && a.id == 1191) || // 株屋根
          // (this.props.section_id == 3 && a.id == 1121) || 
          // (this.props.section_id == 2 && a.id == 1119) || 
          // (this.props.section_id == 2 && a.id == 1419) 
          // (this.props.section_id == 1 && a.id == 1005) || 
          // (this.props.section_id == 1 && a.id == 1014) || 
          // (this.props.section_id == 1 && a.id == 1232)
          ) && (a.holiday === 0 || a.holiday === 3 || a.holiday === 5 || a.holiday === 6 || a.holiday === 7 || a.holiday === 8 || a.holiday === 12 || a.holiday === 13)).map(function (value) {
          return <div>{value.user_name_all}{value.holiday === 3 ? "(AM半休)" : value.holiday === 5 ? "(遅刻)" : value.holiday === 6 ? "(早退)" : value.holiday === 7 ? "(出張)" : value.holiday === 12 ? "(PM半休)" : value.holiday === 12 ? "(外出)" : ""} </div>
      
      })} aria-label="add" placement="bottom-start">
          <div style={{ width: "100%" }}>
            {/*　槙塚さん入れるときは下側 */}
          {/* 出勤{event.users.filter(a => (a.section_id == this.props.section_id) && (a.holiday === 0 || a.holiday === 3 || a.holiday === 5 || a.holiday === 6 || a.holiday === 7 || a.holiday === 8 || a.holiday === 12 || a.holiday === 13)).length}名 */}
            出勤{event.users.filter(a => (
              (a.section_id == this.props.section_id) 
              // (this.props.section_id == 2 && a.id == 1124) // 小野
              // (this.props.section_id == 17 && a.id == 1020) || 
              // (this.props.section_id == 4 && a.id == 1109) // 清藤
              || (this.props.section_id == 6 && a.id == 1015) // 吉田 中央公園
              // || (this.props.section_id == 3 && a.id == 1015) // 吉田　駅前
              // (this.props.section_id == 3 && a.id == 1191) || // 株屋根
              // (this.props.section_id == 3 && a.id == 1121) || 
              // (this.props.section_id == 2 && a.id == 1119) || 
              // (this.props.section_id == 2 && a.id == 1419) 
              // (this.props.section_id == 1 && a.id == 1005) || 
              // (this.props.section_id == 1 && a.id == 1014) || 
              // (this.props.section_id == 1 && a.id == 1232)
              ) && (a.holiday === 0 || a.holiday === 3 || a.holiday === 5 || a.holiday === 6 || a.holiday === 7 || a.holiday === 8 || a.holiday === 12 || a.holiday === 13)).length}名(営業{event.users.filter(a => (
              (a.section_id == this.props.section_id) 
              // (this.props.section_id == 2 && a.id == 1124) // 小野
              // (this.props.section_id == 17 && a.id == 1020) || 
              // (this.props.section_id == 4 && a.id == 1109) // 清藤
              || (this.props.section_id == 6 && a.id == 1015) // 吉田 中央公園
              // || (this.props.section_id == 3 && a.id == 1015) // 吉田 駅前
              // (this.props.section_id == 3 && a.id == 1191) || // 株屋根
              // (this.props.section_id == 3 && a.id == 1121) || 
              // (this.props.section_id == 2 && a.id == 1119) || 
              // (this.props.section_id == 2 && a.id == 1419) 
              // (this.props.section_id == 1 && a.id == 1005) || 
              // (this.props.section_id == 1 && a.id == 1014) || 
              // (this.props.section_id == 1 && a.id == 1232)
              ) && (a.holiday === 0 || a.holiday === 3 || a.holiday === 5 || a.holiday === 6 || a.holiday === 7 || a.holiday === 8 || a.holiday === 12 || a.holiday === 13)
              && !(30 > a.disp_order && a.disp_order >= 20) ).length}名)

          </ div ></Tooltip>);
    } else if (event.type == 4) {
      // alert()
      return (
        <div style={{ width: "100%" }}>
          {/* 前{this.props.calendar_list.filter(a => (a.type == 1 || a.type == 2 || a.type == 3) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T00:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00")).length}&nbsp;
          後{this.props.calendar_list.filter(a => (a.type == 1 || a.type == 2 || a.type == 3) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00")).length}&nbsp;
          夕{this.props.calendar_list.filter(a => (a.type == 1 || a.type == 2 || a.type == 3) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T23:59:59")).length} */}
          <div style={{float:"left"}}><DirectionsWalkIcon />
          <span style={{marginRight:3}}>前{this.props.calendar_list.filter(a => (a.type == 1) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T00:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00")).length}</span>
          <span style={{marginRight:3}}>後{this.props.calendar_list.filter(a => (a.type == 1) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00")).length}</span>
          <span style={{marginRight:3}}>夕{this.props.calendar_list.filter(a => (a.type == 1) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T23:59:59")).length}</span>
            
          </div>
          <div style={{float:"left"}}>
          <DescriptionIcon />
          <span style={{marginRight:3}}>前{this.props.calendar_list.filter(a => (a.type == 2) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T00:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00")).length}</span>
          <span style={{marginRight:3}}>後{this.props.calendar_list.filter(a => (a.type == 2) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00")).length}</span>
          <span>夕{this.props.calendar_list.filter(a => (a.type == 2) && parseInt(a.section_id) == parseInt(this.props.section_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T23:59:59")).length}</span>
          </div>
        </ div >);
    } else if (event.type == 3) {

      return (
        <Tooltip classes={{tooltip: classes.tooltip}} style={{maxWidth:"100px"}} title={(event.start_edit && event.end_edit ? (moment(event.start_edit).format("HH:mm") + "-" + 
      moment(event.end_edit).format("HH:mm")) + ":" : "") + event.title + "(" + event.user_nickname + ")"
      + (event.remarks ? " "+event.remarks : "")}  placement="bottom-start">
        <div
          style={{ width: "100%", height: '100%' }}
          onClick={this.click.bind(this)}
        >
          {event.completed_at ? <CheckCircleIcon style={{color:"#666666"}} />:""}
          {/* <Tooltip title={event.remarks}  placement="bottom-start"> */}
          {/* {event.title + "(" + event.user_nickname + ")"} */}
          {event.title + (event.remarks ? "（" + event.remarks + "）" : "")}
          {/* </Tooltip> */}
        </ div ></Tooltip>);
    } else if (event.type == 1) {
      return (
        <Tooltip classes={{tooltip: classes.tooltip}} style={{maxWidth:"100px"}} title={(event.start_edit && event.end_edit ? (moment(event.start_edit).format("HH:mm") + "-" + 
            moment(event.end_edit).format("HH:mm")) + ":" : "") + event.title
            + (event.remarks ? " "+event.remarks : "")}  placement="bottom-start">
        <div
          style={{ width: "100%", height: '100%' }} onClick={this.click.bind(this)} >

          {event.returning == 0 ? 
            event.rent_hearing_sheet_id > 0 ? <span><FiberNewIcon style={{color: event.intend ? "red" :"#666666" }} /><span className="badge badge-primary" style={{fontWeight:"normal", marginRight:1}}>web</span></span> 
            : <FiberNewIcon  style={{color: event.intend ? "red" :"#666666" }} /> 
          : <DirectionsWalkIcon style={{ color: event.intend ? "red" :"#666666" }} />}
          {event.going == 2 ? <span className="badge badge-primary" style={{fontWeight:"normal", backgroundColor:"gray", marginRight:1}}>現</span> : ""}
          {event.going == 3 ? <span ><LaptopChromebookIcon style={{ color: event.intend ? "red" :"#666666" }} /></span> : ""}
          {event.title}
        </ div></Tooltip>);
    } else if (event.type == 2) {
      return (
        <Tooltip classes={{tooltip: classes.tooltip}} style={{maxWidth:"100px"}} title={(event.start_edit && event.end_edit ? (moment(event.start_edit).format("HH:mm") + "-" + 
            moment(event.end_edit).format("HH:mm")) + ":" : "") + event.title
            + (event.remarks ? " "+event.remarks : "")}  placement="bottom-start">
        <div
          style={{ width: "100%", height: '100%' }} onClick={this.click.bind(this)} >

          {event.way == 2 ? <ContactsIcon style={{color:event.progress != 2 ? "red" : "#666666"}} /> : (event.rent_m_contract_follow_id == 7 || event.rent_m_contract_follow_id == 8) ? <VpnKeyIcon style={{color:event.progress != 2 ? "red" : "#666666"}} /> : <DescriptionIcon style={{color:event.progress != 2 ? "red" : "#666666"}} /> }{event.title}
        </ div></Tooltip>);
    } else if (event.type == 6) {
      return (
        <Tooltip classes={{tooltip: classes.tooltip}} style={{maxWidth:"100px"}} title={(event.start_edit && event.end_edit ? (moment(event.start_edit).format("HH:mm") + "-" + 
            moment(event.end_edit).format("HH:mm")) + ":" : "") + event.title
            + (event.remarks ? " "+event.remarks : "")}  placement="bottom-start">
        <div
          style={{ width: "100%", height: '100%' }} onClick={this.click.bind(this)} >

          {event.type6 == 1 ? event.raiten_kbn_no == 1 || event.raiten_kbn_no == 3 ? <HomeIcon style={{color:event.no_count == 0?"#666666":"red"}} /> : //訪問
          event.raiten_kbn_no == 4 || event.raiten_kbn_no == 6 ? <LocalPhoneIcon style={{color:event.no_count == 0?"#666666":"red"}} /> : // TEL　FAX
          event.raiten_kbn_no == 2 || event.raiten_kbn_no == 7 ? <StoreIcon style={{color:event.no_count == 0?"#666666":"red"}} /> : // 来店
          event.raiten_kbn_no == 5 ? <EmailIcon style={{color:event.no_count == 0?"#666666":"red"}} /> :"" // メール
          :<CorporateFareIcon style={{color:event.no_count == 0?"#666666":"red"}} />}

          {event.title}
        
        </ div></Tooltip>);
    }
  };
  render() {
    return (
      <span>
        {/* {this.state.open_event_editor ? <Card style={{ position: "absolute", zIndex: 3000, width: 500, height: 500, marginLeft: this.state.cardx, marginTop: "-70px" }}>
          <CardContent >
          </CardContent >
        </Card > : ""} */}

        <DragAndDropCalendar
          tooltipAccessor={null}
          selectable
          localizer={globallocalizer}
          min={this.props.calendar_all_store_list_form.start ? new Date(moment(this.props.calendar_all_store_list_form.start).format('YYYY/MM/DD 09:00:00')) : new Date(moment().format('YYYY/MM/DD 09:00:00'))}
          max={this.props.calendar_all_store_list_form.start ? new Date(moment(this.props.calendar_all_store_list_form.start).format('YYYY/MM/DD 18:59:59')) : new Date(moment().format('YYYY/MM/DD 18:59:59'))}
          culture={'ja-JP'}
          events={this.props.calendar_list.filter(a => a.type == 0 || a.type == 4 || (a.type != 5 && a.section_id == this.props.section_id))}
          onEventDrop={this.moveEvent}
          resizable
          onView={this.viewEvent}
          onEventResize={this.resizeEvent}
          onSelectEvent={(event) => this.editEvent(event)}
          // onSelectEvent={event => alert(event.title)}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onTouchStart}
          // scrollToTime={new Date('2021/10/21 16:00:00')} 
          onSelectSlot={this.newEvent}
          // onDragStart={console.log('onDragStart')}
          // onDragOver={console.log('onDragOver')}
          date={this.props.calendar_all_store_list_form.start}
          // date={this.state.baseDate}
          // onNavigate={date => {
          //   this.setState({ baseDate: date });
          // }}

          // onNavigate={this.onNavigate}
          // onDoubleClickEvent={this.dblclick}
          onNavigate={this.onNavigate}
          eventPropGetter={eventStyleGetter}
          components={{
            event: this.Event,

          }}
          defaultView={'day'}
          views={{ agenda: true, week: true, month: true, day: true }}
          toolbar={false}
          // showMultiDayTimes={true}
          formats={{
            dayFormat: (date) => {
              return (moment(date).format('M月DD日(dddd)'));
            },
            agendaDateFormat: (date) => {
              return (moment(date).format('M月DD日(dddd)'));
            },
            dayHeaderFormat: (date) => {
              return (moment(date).format('YYYY年M月DD日(dddd)'));
            },
            agendaHeaderFormat: ({ start, end }) => {
              return (moment(start).format('YYYY年M月DD日') + ' ～ ' + moment(end).format('YYYY年M月DD日'));
            },
            dayRangeHeaderFormat: ({ start, end }) => {
              return (moment(start).format('YYYY年M月DD日') + ' ～ ' + moment(end).format('YYYY年M月DD日'));
            },
          }}
          messages={{
            date: '日付',
            time: '時間',
            event: '内容',
            allDay: '終日',
            week: '週',
            work_week: 'Work Week',
            day: '日',
            month: '月',
            previous: '←前へ',
            next: '次へ→',
            yesterday: '昨日',
            tomorrow: '明日',
            today: '今日',
            agenda: '一覧',
            noEventsInRange: '期間内に登録されているイベントがありません',
          }}

          style={{ height: window.innerHeight  - 131}}
        />
        {/* <SimpleDialog selectedValue={1} open={this.state.open} onClose={this.handleClose} change_modalResponseDetail={this.props.change_modalResponseDetail} responseid={this.props.responseid} /> */}
        {/* <TaskDialog
          key={this.props.section_id}
          id={this.props.section_id}
          selectedValue={1}
          open={this.state.open}
          onClose={this.handleClose}
          event_type={this.state.event_type}
          change_contract_follow_edit_modal={this.props.change_contract_follow_edit_modal}
          contract_follow_edit_modal={this.props.contract_follow_edit_modal}
          change_customer_contacts_modal={this.props.change_customer_contacts_modal}
          customer_contacts_modal={this.props.customer_contacts_modal} /> */}
        {/* <Task ref={this.ChildRef} /> */}
        {/* <Task ref={this.ChildRef} /> */}
      </span>
    );
  }
};


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    users: state.calendar.users,

    calendar_list: state.calendar.calendar_list,
    calendar_all_store_list_form: state.calendar.calendar_all_store_list_form,

    rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
    contract_follow_editer: state.contractfollow.contract_follow_editer,
    eigyo_edit_modal: state.calendar.eigyo_edit_modal,
    eigyou_detail: state.calendar.eigyou_detail,
    rent_task_detail: state.calendar.rent_task_detail,
    contract_follow_edit_modal: state.contractfollow.contract_follow_edit_modal,
    customer_contacts_modal: state.customerContactsReducer.customer_contacts_modal,
    task_edit_modal: state.contractfollow.task_edit_modal,
    tm_owner_outai_naiyou_list: state.calendar.tm_owner_outai_naiyou_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },






    change_calendar_all_store_list_form(state) {
      dispatch(Calendarsaga.change_calendar_all_store_list_form(state))
    },
    change_calendar_list(state) {
      dispatch(Calendarsaga.change_calendar_list(state))
    },
    get_eigyou_rent_m_corporate_all_list(state) {
      dispatch(Calendarsaga.get_eigyou_rent_m_corporate_all_list(state))
    },
    get_eigyou_owner_room(state) {
      dispatch(Calendarsaga.get_eigyou_owner_room(state))
    },
    change_eigyo_edit_modal(state) {
      dispatch(Calendarsaga.change_eigyo_edit_modal(state))
    },
    change_eigyou_detail(state) {
      dispatch(Calendarsaga.change_eigyou_detail(state))
    },
    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_rent_customer_contacts_forms(state) {
      dispatch(CustomerContacts.change_rent_customer_contacts_forms(state))
    },
    change_task_edit_modal(state) {
      dispatch(Calendarsaga.change_task_edit_modal(state))
    },
    change_contract_follow_edit_modal(state) {
      dispatch(ContractFollow.change_contract_follow_edit_modal(state))
    },
    change_customer_contacts_modal(state) {
      dispatch(CustomerContacts.change_customer_contacts_modal(state))
    },
    change_calendar_event_type(state) {
      dispatch(Calendarsaga.change_calendar_event_type(state))
    },
    change_calendar_precalendar(state) {
      dispatch(Calendarsaga.change_calendar_precalendar(state))
    },
    get_t_owner_plan_counts_list(state) {
      dispatch(Calendarsaga.get_t_owner_plan_counts_list(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RBCalendar);