
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as SearchRoomsApi from '../Api/SearchRoomsApi';
import * as RentIntroductionFilesApi from '../Api/RentIntroductionFilesApi';
import * as RentIntroductionFileUploadApi from '../Api/RentIntroductionFileUploadApi';
import * as RentIntroductionDetailsApi from '../Api/RentIntroductionDetailsApi';
import * as RentIntroductionFilesDownloadApi from '../Api/RentIntroductionFilesDownloadApi';
import * as rent_introduction_headers_api from '../Api/rent_introduction_headers_api';
import * as rent_introductions_api from '../Api/rent_introductions_api';



export interface RoomsState {
  setsubi_master: any;
  rooms: any;
  room_details: any;
  response_rooms: any;
  introductions: any;
  introductions_loading: number;
  introduction_file_id: number;
  introduction_pdf: any;
  introduction_pdf_img: any;
  introduction_details: any;
  introduction_detail_loading: boolean;

  //  rent_introductions: any;
}
const initialState: RoomsState = {

  setsubi_master: [],
  rooms: [],
  room_details: {},
  response_rooms: [],
  introductions: [],
  introductions_loading: 0,
  introduction_file_id: 0,
  introduction_pdf: null,
  introduction_pdf_img: null,
  introduction_details: {},
  introduction_detail_loading: true,
  // rent_introductions: {}

}



///action 
const actionCreator = actionCreatorFactory();

// export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
// export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");
// export const get_setsubi_master_failure = actionCreator("GET_SETSUBI_MASTER_FAILURE");



export const get_rooms = actionCreator('GET_ROOMS');
export const get_rooms_success = actionCreator("GET_ROOMS_SUCCESS");
export const get_rooms_failure = actionCreator("GET_ROOMS_FAILURE");


export const get_response_rooms = actionCreator<any>('GET_RESPONSE_ROOMS');
export const get_response_rooms_success = actionCreator<any>("GET_RESPONSE_ROOMS_SUCCESS");


// export const get_room_details = actionCreator<any>('GET_ROOM_DETAILS');
// export const get_room_details_success = actionCreator("GET_ROOM_DETAILS_SUCCESS");
// export const get_room_details_failure = actionCreator("GET_ROOM_DETAILS_FAILURE");

export const get_introduction_details = actionCreator<any>('GET_INTRODUCTION_DETAILS');
export const get_introduction_details_success = actionCreator("GET_INTRODUCTION_DETAILS_SUCCESS");
export const change_introduction_detail_loading = actionCreator<any>('CHANGE_INTRODUCTION_DETAIL_LOADING');


export const get_introductions = actionCreator<any>('GET_INTRODUCTIONS');
export const get_introductions_success = actionCreator<any>('GET_INTRODUCTIONS_SUCCESS');

export const insert_introductions = actionCreator<any>('INSERT_INTRODUCTIONS');
export const insert_introductions_success = actionCreator<any>('INSERT_INTRODUCTIONS_SUCCESS');

export const insert_introduction_files = actionCreator<any>('INSERT_INTRODUCTION_FILES');
export const change_introduction_file_id = actionCreator<any>('CHANGE_INTRODUCTION_FILE_ID');

export const change_introduction_pdf = actionCreator<any>('CHANGE_INTRODUCTION_PDF');
export const change_introduction_pdf_img = actionCreator<any>('CHANGE_INTRODUCTION_PDF_IMG');

export const update_open_introductions = actionCreator<any>('UPDATE_OPEN_INTRODUCTIONS');
export const update_open_introductions_success = actionCreator<any>('UPDATE_OPEN_INTRODUCTIONS_SUCCESS');

export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');

export const delete_introduction = actionCreator<number>('DELETE_INTRODUCTION');


export const add_introduction_file = actionCreator<any>('ADD_INTRODUCTION_FILE');


export const get_introduction_file = actionCreator<any>('GET_INTRODUCTION_FILE');

export const update_introduction_list_toiawase = actionCreator<any>('UPDATE_INTRODUCTION_LIST_TOIAWASE');

//  const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const introductionlistReducer = reducerWithInitialState(initialState)
  // .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
  // .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))
  .case(get_introduction_details_success, (state, payload) => ({ ...state, introduction_details: payload }))

  .case(change_introduction_detail_loading, (state, payload) => ({ ...state, introduction_detail_loading: payload }))



  .case(get_response_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

  .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))

  .case(change_introductions_loading, (state, payload) => ({ ...state, introductions_loading: payload }))

  .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }))

  .case(change_introduction_file_id, (state, payload) => ({ ...state, introduction_file_id: payload }))


  .case(change_introduction_pdf, (state, payload) => ({ ...state, introduction_pdf: payload }))
  .case(change_introduction_pdf_img, (state, payload) => ({ ...state, introduction_pdf_img: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const introductionlistSaga = [
  takeEvery('GET_ROOMS', handleGetRoomsRequest),
  // takeEvery('GET_ROOM_DETAILS', handleGetRoomDetailsRequest),
  takeEvery('GET_RESPONSE_ROOMS', handle_get_response_rooms),
  takeEvery('GET_INTRODUCTIONS', handle_get_introductions),
  takeEvery('INSERT_INTRODUCTIONS', handle_insert_introductions),
  takeEvery('UPDATE_OPEN_INTRODUCTIONS', handle_update_open_introductions),
  takeEvery('DELETE_INTRODUCTION', handle_delete_introduction),
  takeEvery('INSERT_INTRODUCTION_FILES', handle_insert_introduction_files),
  takeEvery('ADD_INTRODUCTION_FILE', handle_add_introduction_file),
  takeEvery('GET_INTRODUCTION_DETAILS', handle_get_introduction_details),

  takeEvery('GET_INTRODUCTION_FILE', handle_get_introduction_file),

  takeEvery('UPDATE_INTRODUCTION_LIST_TOIAWASE', handle_update_introduction_list_toiawase),


  // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
  // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;





function* handleGetRoomsRequest(action: any) {

  const { payload, error } = yield call(SearchRoomsApi.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(get_rooms_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


//  function* handleGetRoomDetailsRequest(action:any) {

//   const { payload, error }= yield call(SearchRoomsApi.detail, action.payload);

//      // サーバーからのレスポンスデータによる分岐処理
//      if (payload && !error) {
//          console.log(payload)

//          yield put(get_room_details_success(payload));

//      } else {
//      //    // 失敗時アクション呼び出し
//      //    yield put(loginFailure(payload));
//      }
//  }



function* handle_get_introduction_details(action: any) {

  yield put(change_introduction_detail_loading(true));
  const { payload, error } = yield call(RentIntroductionDetailsApi.detail, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(get_introduction_details_success(payload));
    yield put(change_introduction_detail_loading(false));
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_get_response_rooms(action: any) {

  const { payload, error } = yield call(SearchRoomsApi.response_list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(get_response_rooms_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}












function* handle_get_introductions(action: any): any{
  console.log(action.payload)

  const state = yield select();

  yield put(change_introductions_loading(1));
  // yield put(get_introductions_success({}));
  // alert(action.payload.rent_response_id)
  const { payload, error } = yield call(rent_introductions_api.list, action.payload.rent_response_id);

  if (payload && !error) {

    // let stock_rooms = state.StockRooms.stock_rooms
    // alert(stock_rooms.length)
    // console.log("abbbbbbbbbbbbbbbb",payload)
    // let lists = payload.map(function (value: any) {
    //   if(stock_rooms.filter((a: any) => String(a.tatemono_id) == String(value.tatemono_id) && String(a.room_id) == String(value.room_id))[0]){
    //     alert(stock_rooms.filter((a: any) => a.tatemono_id == value.tatemono_id && a.room_id == value.room_id)[0].tatemono_id)
    //   }else{
    //     // alert( value.tatemono_id)
    //   }
      
    //     value.stocked = stock_rooms.filter((a: any) => String(a.tatemono_id) == String(value.tatemono_id) && String(a.room_id) == String(value.room_id))[0]
    //     return value
    // })


    //   const { payload_bookmark, error_bookmark } = yield call(rent_introductions_api.isbookmark, action.payload.rent_response_id);
    //   // 成功時アクション呼び出し
    //   console.log(payload)
    //   if (payload_bookmark && !error_bookmark) {
    //     console.log(payload_bookmark)

    //     let list = payload.map(function (value: any) {
    //       if (payload_bookmark.filter((a: any) => a.tatemono_id === value.tatemonoId && a.room_id === value.roomId)[0]) {
    //         value.is_bookmark = payload_bookmark.filter((a: any) => a.tatemono_id === value.tatemonoId && a.room_id === value.roomId)[0].is_bookmark
    //       } else {
    //         value.is_bookmark = false
    //       }
    //       return value
    //     })

//     let stock_rooms = state.StockRooms.stock_rooms
// console.log("stock_rooms", stock_rooms)
//     let intro = payload.map(function (value: any) {

//       if (stock_rooms.filter((a: any) => a.tatemono_id === value.tatemono_id && a.room_id === value.room_id)[0]) {
//         value.stocked = true
//       } else {
//         value.stocked = false


//       }


//       return value
//     })

    yield put(get_introductions_success([...payload]));
    yield put(change_introductions_loading(0));
    // yield put(change_customer_logs_loading(0));
    // } else {

    // }
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_insert_introductions(action: any): any {

  const state = yield select();　　// store.getState()と同じ

  console.log(action.payload)

  yield put(change_introductions_loading(1));
  const { payload, error } = yield call(RentIntroductionsApi.insert, action.payload);

  if (payload && !error) {
    // 成功時アクション呼び出し
    //   console.log(payload)
    //   // alert()
    // alert(payload.rentResponseId)
    //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
    //   if (payload && !error) {  



    
    // カレンダー付からダイアログ開いたとき
    // alert(state.customerContactsReducer.customer_contacts_modal)
    // alert(state.calendar.calendar_list_edit_dialog_disp)
    if(state.customerContactsReducer.customer_contacts_modal && state.calendar.calendar_list_edit_dialog_disp){
        // alert("更新しない")
    }else{
        // alert("更新")

      yield put(get_introductions({ rent_response_id: action.payload.rentResponseId }));
      yield put(change_introductions_loading(0));

    }

    // } else {
    //   alert(error)
    //   //    // 失敗時アクション呼び出し
    //   //    yield put(loginFailure(payload));
    //   }
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_update_open_introductions(action: any) {
  console.log(action.payload)

  yield put(change_introductions_loading(1));
  const { payload, error } = yield call(rent_introductions_api.update, action.payload);

  if (payload && !error) {
    // 成功時アクション呼び出し
    //   console.log(payload)
    //   // alert()
    // alert(payload.rentResponseId)
    //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
    //   if (payload && !error) {  

    // yield put(get_introductions_success(payload));
    yield put(get_introductions({ rent_response_id: action.payload.rent_response_id }));
    yield put(change_introductions_loading(0));

    // } else {
    //   alert(error)
    //   //    // 失敗時アクション呼び出し
    //   //    yield put(loginFailure(payload));
    //   }
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


function* handle_delete_introduction(action: any) {
  console.log(action.payload)

  yield put(change_introductions_loading(1));
  const { payload, error } = yield call(RentIntroductionsApi.deleteintro, action.payload.rentIntroductionId);

  if (payload && !error) {
    // 成功時アクション呼び出し
    //   console.log(payload)
    //   // alert()
    // alert(payload.rentResponseId)
    //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
    //   if (payload && !error) {  

    yield put(get_introductions({ rent_response_id: action.payload.rent_response_id }));
    // yield put(change_introductions_loading(0));

    // } else {
    //   alert(error)
    //   //    // 失敗時アクション呼び出し
    //   //    yield put(loginFailure(payload));
    //   }
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}





function* handle_insert_introduction_files(action: any): any {
  console.log(action.payload)

  yield put(change_introductions_loading(1));


  const { payload, error } = yield call(RentIntroductionFilesApi.insert, action.payload);
  const state = yield select();
  if (payload && !error) {
    // 成功時アクション呼び出し
    //   console.log(payload)
    //   // alert()
    // alert(payload.rentResponseId)
    //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
    //   if (payload && !error) {  
    // alert(payload.id)

    if (state.introductionlist.introduction_pdf != null) {
      var pdf = state.introductionlist.introduction_pdf
      pdf.id = payload.id
      yield put(add_introduction_file(pdf))
      var img = state.introductionlist.introduction_pdf_img
      img.id = payload.id
      yield put(add_introduction_file(img))
    }

    yield put(get_introductions({ rent_response_id: action.payload.rentResponseId }));

    yield put(change_introduction_file_id(payload.id));

    yield put(change_introduction_pdf(null));
    yield put(change_introduction_pdf_img(null));
    // // yield put(change_introductions_loading(0));

    // } else {
    //   alert(error)
    //   //    // 失敗時アクション呼び出し
    //   //    yield put(loginFailure(payload));
    //   }
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


function* handle_add_introduction_file(action: any) {

  // yield put(change_get_mail_detail_files_loading(1))
  // console.log( action.payload)
  // yield put(get_mail_detail_files_success([]))
  const { payload, error } = yield call(RentIntroductionFileUploadApi.add_file, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {


    //  yield put(get_mail_detail_files_success(payload))

    //  yield put(change_get_mail_detail_files_loading(0))




  } else {


  }
}



function* handle_get_introduction_file(action: any) {

  const { payload, error } = yield call(RentIntroductionFilesDownloadApi.download, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)
    const a = document.createElement('a');
    // ダウンロードされるファイル名
    a.download = "物件資料.pdf";
    a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}





function* handle_update_introduction_list_toiawase(action: any) {
  console.log(action.payload)

  yield put(change_introductions_loading(1));

  const { payload, error } = yield call(rent_introductions_api.update, action.payload);

  if (payload && !error) {
    // 成功時アクション呼び出し
    //   console.log(payload)
    //   // alert()
    // alert(payload.rentResponseId)
    //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
    //   if (payload && !error) {  

    // yield put(get_introductions_success(payload));
    yield put(get_introductions({ rent_response_id: action.payload.rent_response_id }));
    yield put(change_introductions_loading(0));

    // } else {
    //   alert(error)
    //   //    // 失敗時アクション呼び出し
    //   //    yield put(loginFailure(payload));
    //   }
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}
