
const authentication = require('../../../react-azure-adb2c2').default;
export const contract_file = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/contract/` + values + '/A4';
    // const url = `http://localhost/laravelapi/public/api/contract/` + values + '/A4';
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())//.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const chushajo = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/chushajo/` + values + '/A4';
    // const url = `http://localhost/laravelapi/public/api/contract/` + values + '/A4';
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())//.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const parkingContracts = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    // const url = `http://localhost/laravelapi/public/api/parkingContracts/` + values + '/A4';
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/parkingContracts/` + values + '/A4';
    // const url = `http://localhost/laravelapi/public/api/contract/` + values + '/A4';
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())//.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const disclosure_statement_file = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    // const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/disclosureStatement/` + values + '/A4';
    // const url = 'http://localhost/laravelapi/public/api/disclosureStatement/' + values + '/A4';
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/disclosureStatement/` + values + `/A4`;
    // const url = 　`http://localhost/laravelapi/public/api/disclosureStatement/`+values+`/A4`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())//.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const LifeLine = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;

    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/Lifeline/` + values + '/A3';
    // const url = 'http://localhost/laravelapi/public/api/Lifeline/' + values + '/A3';
    // const url = `https://doc.homestation.jp/api/contract/106790/A4`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // console.log(token)
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        //.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const NetPikara = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/NetPikara/` + values;
    // const url = 'http://localhost/laravelapi/public/api/NetPikara/'+values
    // const url = 'http://localhost/laravelapi/public/api/Lifeline/' + values + '/A3';
    // const url = `https://doc.homestation.jp/api/contract/106790/A4`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // console.log(token)
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        //.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const Jippi = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;

    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/ActualExpenseSettlement/` + values;
    // const url = 'http://localhost/laravelapi/public/api/Lifeline/' + values + '/A3';
    // const url = `https://doc.homestation.jp/api/contract/106790/A4`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // console.log(token)
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        //.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const Shikibiki = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/ShikibikiSettlement/` + values;
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const support365 = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/support365/` + values + '/A4';
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const hazardmap = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/hazardmap/` + values.id;
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}
export const mimamori = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/Mimamori24/` + values + '/A4';
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}
export const kaketsuke = (values: any) => {
    // const url = `http://localhost/laravelapi/public/api/kaketsuke/` + values.id + '/A4';
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/kaketsuke/` + values + '/A4';
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

        
    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}

export const kaden = (values: any) => {
    // const url = `http://localhost/laravelapi/public/api/kaden/` + values.id + '/A4';
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/kaden/` + values + '/A4';
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

        
    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}
export const kadenShiyou = (values: any) => {
    // const url = `http://localhost/laravelapi/public/api/kadenShiyou/` + values.id + '/A4';
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/kadenShiyou/` + values + '/A4';
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

        
    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}

export const jousui = (values: any) => {
    // const url = `http://localhost/laravelapi/public/api/jousuikiYakkan/` + values.id + '/A4';
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/jousuikiYakkan/` + values + '/A4';
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

        
    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}

export const getkouku = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/getkouku/` + values;
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => { console.log(payload); return ({ payload }) })
        .catch(error => { console.log(error); return ({ error }) });
}

export const get_QR = (values: any) => {
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/getQrCode`;
    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.blob())//.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}


export const seikyuu = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/seikyuusho/` + values + '/A4';
    // const url = `http://localhost/laravelapi/public/api/contract/` + values + '/A4';
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

    }).then(res => res.blob())//.then(res => console.log(res,"れす確認"))
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
